<template>
  <div class="eyeScene">
    <h3 style="padding-top: 0.2rem;">你当前的眼部症状有哪些？</h3>
    <div class="nr-box">
      <el-checkbox-group v-model="checkList">
        <div class="nr-content">
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src='srcList[0]'
                  :preview-src-list="srcList">
              </el-image>
              <p>揉眼</p>
            </div>
            <div class="xzx">
              <el-select v-model="postData.S_OP_ICH_SeQ1" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="0"></el-checkbox>
              <el-input v-model="postData.S_OP_ICH_SeH1" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src='srcList[1]'
                  :preview-src-list="srcList">
              </el-image>
              <p>眨眼</p>
            </div>
            <div class="xzx">
              <el-select v-model="postData.S_OP_ICH_SeQ2" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="1"></el-checkbox>
              <el-input v-model="postData.S_OP_ICH_SeH2" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src='srcList[2]'
                  :preview-src-list="srcList">
              </el-image>
              <p>歪头视物</p>
            </div>
            <div class="xzx">
              <el-select v-model="postData.S_OP_ICH_SeQ3" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="2"></el-checkbox>
              <el-input v-model="postData.S_OP_ICH_SeH3" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  sstyle="width: 100%; height: 100%"
                  :src='srcList[3]'
                  :preview-src-list="srcList">
              </el-image>
              <p>眯眼视物</p>
            </div>
            <div class="xzx">
              <el-select v-model="postData.S_OP_ICH_SeQ4" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="3"></el-checkbox>
              <el-input v-model="postData.S_OP_ICH_SeH4" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src='srcList[4]'
                  :preview-src-list="srcList">
              </el-image>
              <p>漏字/漏行/串行</p>
            </div>
            <div class="xzx">
              <el-select v-model="postData.S_OP_ICH_SeQ5" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="4"></el-checkbox>
              <el-input v-model="postData.S_OP_ICH_SeH5" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src='srcList[5]'
                  :preview-src-list="srcList">
              </el-image>
              <p>眼胀头疼</p>
            </div>
            <div class="xzx">
              <el-select v-model="postData.S_OP_ICH_SeQ6" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="5"></el-checkbox>
              <el-input v-model="postData.S_OP_ICH_SeH6" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>
        </div>
      </el-checkbox-group>
    </div>
    <div class="bz">
      <p>现病史：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入内容"
          v-model="postData.S_OP_ICH_remark2">
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "teenagerSymptoms",
  data() {
    return {
      checkList:[],
      srcList: [
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/CIP2_1.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/CIP2_2.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/CIP2_3.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/CIP2_4.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/CIP2_5.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/CIP2_6.jpg"
      ],
      postData: {},
      yybz: '',
      value: '',
      options: [
        {
          value: '左眼',
          label: '左眼'
        },
        {
          value: '右眼',
          label: '右眼'
        },
        {
          value: '双眼',
          label: '双眼'
        },
        {
          value: ' ',
          label: ' '
        }
      ]
    }
  },
  watch: {
    checkList: {
      handler(newName, oldName) {
        if (!oldName) {
          return
        }
        let arr = [0,0,0,0,0,0]
        this.checkList.forEach(item => {
          arr[item] = 1
        })
        this.postData.S_OP_ICH_symptomArray = arr.join(',')
        let items = this.postData
        this.$store.commit('physicianVisits/upTeenagerData', {type: 2, obj: items})
      },
      immediate: true,
      deep: true
    },
    postData: {
      handler(n,o) {
        if (o) {
          this.$store.commit('physicianVisits/upTeenagerData', {type: 2, obj: n})
        }
      },
      deep: true,
      immediate: true
    },
    "$store.state.physicianVisits.isgetData":function (n, o) {
      setTimeout(() => {
        this.inits()
      }, 500)
    }
  },
  created() {

  },
  methods: {
    inits() {
      let datas = JSON.parse(JSON.stringify(this.$store.state.physicianVisits.teenager))
      let arr = '',newarr = [],
          ybzz = JSON.parse(JSON.stringify(this.$store.state.physicianVisits.teenagerYbzz))
      if (datas.S_OP_ChooseId) {
        for (let key in ybzz) {
          ybzz[key] = datas.S_OP_Json[key]
        }
      }
      this.postData = ybzz
      if (this.postData.S_OP_ICH_symptomArray) {
        if (this.postData.S_OP_ICH_symptomArray instanceof Array) {
          arr = this.postData.S_OP_ICH_symptomArray
        }else {
          arr = this.postData.S_OP_ICH_symptomArray.split(',')
        }
        if (arr && arr.length > 0) {
          for (let i = 0; i < arr.length; i++) {
            if (Number(arr[i])) {
              newarr.push(i.toString())
            }
          }
        }
        this.checkList = newarr
      }
    }
  }
}
</script>

<style scoped lang="scss">
.xzx {
  display: flex;
  align-items: center;
  justify-content: start;
  .zxz-sr {width: 80px;}
}

.fxk {margin: 0 .05rem;}
::v-deep .fxk .el-checkbox__inner::after {
  width: 8px;
  height: 16px;
  left: 10px;
  top: 2px;
}
::v-deep .fxk .el-checkbox__inner {
  width: 30px;
  height: 30px;
}
::v-deep .fxk .el-checkbox__label {display: none;}
.nr-box {
  img {
    width: 10vw;
    border-radius: 5px;
  }
}
.nr-content {
  display: flex;
  width: 85vw;
  flex-wrap: wrap;
  padding: 0 2vw;
  .zynr {
    width: 140px;
    height: 140px;
    padding: 4px;
    border: 2px solid #8f8e8e;
    border-radius: 5px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 8px;
    p {
      position: absolute;
      bottom: 4px;
      left: 4px;
      width: calc(100% - 8px);
      height: 30%;
      background: rgba(0,0,0,0.5);
      color: #ffffff;
      font-size: 14px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .nr-b-box {
    margin:0 10px;
    padding-top: 20px;
  }
}
.bz {
  width: 76vw;
  padding: 0 3vw;
  text-align: left;
  padding-top: 0.3rem;
}
</style>
